<template>
  <p class="gavc-p">
    <strong>En tant que {{me}}</strong>, durant cette phase, il vous est nécessaire de :
    <ul>
      <li><strong>Renseigner les informations et charger les documents</strong> demandés ci-dessous</li>
      <li><strong>Vérifier les informations renseignées</strong> et <strong>valider les documents chargés</strong> par le {{him}}</li>
      <li><strong>Valider cette phase</strong> (cette validation signifie que vous validez vos informations, documents mais également celles et ceux du {{him}})</li>
    </ul>
  </p>
</template>

<script>
export default {
  name: 'auditExplanation',
  props: {
    isCedant: {
      type: Boolean,
      default:true
    },
    isRepreneur: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    me () {
      if (this.isCedant) {
        return "cédant"
      }
      return "repreneur"
    },
    him () {
      if (this.isCedant) {
        return "repreneur"
      }
      return "cédant"
    }
  }
}
</script>
